<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <router-link :to="{ name: 'autoskola-cene-obuke-kreiraj' }">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
          >
            {{ $t("KreirajSablon") }}
          </b-button>
        </router-link>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body :title="$t('Sabloni')">
        <div>
          <b-table
            v-if="Object.entries(items).length"
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(id)="data">
              <b-button-group size="sm">
                <router-link
                  :to="{
                    name: 'autoskola-cene-obuke-izmeni',
                    params: { id: data.item.id }
                  }"
                >
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t("Izmeni") }}
                  </b-button>
                </router-link>
                <b-button
                  variant="danger"
                  size="sm"
                  @click="modalBrisanje(data.item.id, `${data.item.naziv}`)"
                >
                  {{ $t("Obriši") }}
                </b-button>
              </b-button-group>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaKreiranihSablona') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-brisanje-sablona"
      ref="modal-brisanje-sablona"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      :ok-title="$t('Obrisi')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="$t('ObrisiSablon')"
      size="lg"
      @ok="obrisiSablon"
    >
      <div class="d-block text-left">
        <p>
          {{ $t('DaLiSteSigurniDaZeliteDaObriseteSablon:') }}
        </p>
        <p>
          {{ podaciSablonaZaBrisanje.naziv }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCardBody,
  BButtonGroup,
  BCard,
  BAlert,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BButton,
    BCardBody,
    BButtonGroup,
    BCard,
    BAlert,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'naziv', label: this.$i18n.t('Naziv') },
        { key: 'kategorija_naziv', label: this.$i18n.t('Kategorija') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      items: [],
      podaciSablonaZaBrisanje: {},
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get('/autoskole-administratori/cene-obuke').then(response => {
        this.items = response.data
      })
    },
    modalBrisanje(id, naziv) {
      this.podaciSablonaZaBrisanje = {
        id,
        naziv,
      }
      this.$bvModal.show('modal-brisanje-sablona')
    },
    obrisiSablon() {
      this.$http.delete(`/autoskole-administratori/cene-obuke/${this.podaciSablonaZaBrisanje.id}`).then(() => {
        this.$bvModal.hide('modal-brisanje-sablona')
        this.pokupiListu()
      })
    },
  },
}
</script>
